@import '../theme/variables';

.expert-list {
  --background: var(--ion-color-primary-tint-light);

  ion-list {
    background: transparent;
  }

  ion-item {
    --background: transparent
  }

  ion-card {
    background: var(--ion-color-primary-contrast);
    width: 100%;

    .card-content-ios{
      padding-left: unset;
      padding-right: unset;
      -webkit-padding-start: 14px;
      padding-inline-start: 14px;
      -webkit-padding-end: 14px;
      padding-inline-end: 14px;
    }

  }

  @media (max-height: 600px) {
    ion-card {
      .card-content-ios {
        -webkit-padding-start: 5px;
        padding-inline-start: 5px;
        -webkit-padding-end: 5px;
        padding-inline-end: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }

    ion-card.sc-ion-card-ios-h {
      margin-inline-start: 0px;
      -webkit-margin-start: 0px;
      margin-inline-end: 5px;
      -webkit-margin-end: 5px;
      margin-top: 10px;
      margin-bottom: 5px;
    }
  }
}

