@import '../../theme/variables.scss';

.conversation-lists-container ion-footer {
  position: sticky;
  bottom: 0;
  background-color: var(--ion-color-primary-contrast);
}

.conversation-lists {
  padding: 0;

  ion-item-group {
    padding-bottom: 20px;
    background-color: var(--ion-color-primary-contrast);
  }

  .sticky {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .sticky-after-active {
    top: 99px;
  }

  ion-icon {
    margin-right: 10px;
  }

  .conversation-item ion-icon {
    margin-right: 10px;
  }
}

.conversation-item {
  cursor: pointer;

  &:hover {
    color: var(--ion-color-primary);
  }
}
