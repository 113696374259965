@import '../theme/variables';

.payment-modal {
  --background: var(--ion-color-primary) !important;
  ion-header:after {
    background: none;
  }
  ion-toolbar {
    --background: transparent;
    --ion-color-base: transparent !important;
    background: none;
    border: 0;
    .toolbar-background{
      border:0;
    }
    ion-button{
      color: var(--ion-color-primary-contrast);
    }
  }

  ion-content {
    --background: transparent;
    --padding-start: 30px;
    --padding-end: 30px;
    --padding-top: 15px;
    --padding-bottom: 15px;
    --color: var(--ion-color-primary-contrast);
  }

  ion-list {
    padding: 0;
    background: transparent;
  }

  ion-list-header {
    padding: 0;
    color: var(--ion-color-primary-contrast);
  }

  ion-item {
    margin: 5px 0;
    --padding-start: 0;
    --background: transparent;
    --color: var(--ion-color-primary-contrast);

    ion-col {
      padding: 0;
    }

    h4, p, ion-button {
      margin: 8px 0;
    }

    ion-button {
      --padding-top: 15px;
      --padding-bottom: 15px;
      height: 100%;
    }
  }

  .cancel-button {
    text-align: center;
  }
  .currency-footer{
    text-align: center;
  }

  ion-button[type="button"],
  ion-router-link {
    color: var(--ion-color-primary-contrast);
    text-transform: initial;
  }
}
