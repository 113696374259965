@import '../../theme/variables.scss';

.background {
  background-color: white;
  border-radius: 4px;
}

ion-button {
  --padding-bottom: 1.2rem;
  --padding-top: 1.2rem;
}

ion-item {
  --inner-border-width: 0;
}

ion-avatar {
  width: 70px;
  height: 70px;
}

.striked-minute-rate {
  -webkit-text-decoration-line: line-through;
  text-decoration: line-through;
  padding: 0.5rem;
}

.wh-auto {
  width: auto;
  height: auto;
}

.country {
 color: dimgrey;
}

.expert-summary {
  color: $title-color;
  font-weight: bold;
}

.expert-list {
  .expert-summary {
    color: black;
    font-weight: normal;
  }
}

.expert-description {
  padding: 5px 0;
  font-size: 0.9rem;

  div {
    padding: 5px 0;
  }

  :last-child {
    color: $title-color;
    font-weight: bold;
  }
}
.character-content {
  border: 1px solid white;
  border-radius: 5px;
}
