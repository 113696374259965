.phone{
  overflow:auto;
  width:50%;
  height:100%;
  margin: auto;
}
.chat-frame{
  @media screen and (min-width: 768px){
    margin-top:40px;
    margin-bottom:40px;
    //border: 1px solid var(--ion-color-primary);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }


}

.bla-theme{
  background: url('../assets/img/bla/background.jpg') no-repeat fixed !important; //TODO change this to internal url by theme
  background-size: cover !important;
}
