@import '../../theme/variables.scss';

.header-image {
  width: 40px;
  height: 40px;
  border-radius: 20px;

  ion-avatar {
    max-width: 100%;
    height: auto;
    border: 2px solid #eee;

    img {
      display: block;
    }
  }
}

.toolbar-item {
  background: $chat-bg-color;
  --background: $chat-bg-color;
  color: #fff;

  ion-fab-button {
    --background: transparent;
    --background-hover: rgba(66,66,66,0.08);
    --box-shadow: none;
  }

  .sc-ion-back-button-ios {
    color: #fff;
  }
}

.header-meta {
  padding-right: 10px;
  color: #fff;
}

.header-mood {
  font-size: 14px;
}

.header-expert-category {
  display: inline-block;
  font-size: 14px;
  margin-right: 10px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.header-name {
  font-size: 26px;
}

.chat-content {
  height: 90%;
}

.header-name ion-button {
  margin-right: 10px;
}

.chat-item {
  background: transparent;
  --background: transparent;
  --inner-border-width: 0;
  border-width: 0;

  &-row {
    width: 100%;
  }

  &-col {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  }

  &--start ion-label p {
    color: var(--ion-color-success);
    font-weight: bold;
  }

  &--warning ion-label p{
    color: var(--ion-color-warning);
    font-weight: bold;
  }

  &--alert ion-label p{
    color: var(--ion-color-danger);
    font-weight: bold;
  }

  &--message {
    .chat-item-col {
      justify-content: flex-start;
      padding: 0;
    }

    .chat-item--container {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      overflow: hidden;

      ion-label p {
        margin: 0;
        padding: 14px;
        color: #444;
        font-size: 16px;
      }
    }

    .chat-item--container--text {
      text-align: justify;
      position: relative;
      border: 2px solid #ccc;
      max-width: 72vw;
      background: transparent;
      font-weight: 500;
      white-space: pre-wrap !important;
    }
  }

  &--right {
    .chat-item-col {
      justify-content: flex-end;
    }

    .chat-item--container {
      justify-content: flex-end;

      ion-label p {
        color: white;
      }
    }

    .chat-item--container--text {
      border-color: transparent;
      background: $chat-bg-color;
    }
  }
}

.timer-item {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-left: 10px;

  &--right {
    justify-content: flex-end;
    padding-left: 0px;
    padding-right: 10px;
  }

  &--text {
    font-size: 11px;
    margin: 0 0 0 1px;
    color: #808080a8;
    margin-top: 0;
  }
}

.first-tick {
  color: $check-mark-true;
}

.chat-item-ion-options {
  background: transparent;
  --background: transparent;
  max-height: calc(100% - 28px);

  ion-item-option {
    background: transparent;
    --background: transparent;
  }
}

.chat-item-time {
  font-size: 12px;
  color: var(--ion-color-primary);
  padding: 4px 8px;
  height: 24px;
  border-radius: 12px;
  line-height: 16px;
  border: 1px solid var(--ion-color-primary-shade);
}

/* footer chat */
.input-container {
  width: 80%;
}

.btn-send-container {
  bottom: 5px !important;
}

.btn-send {
  width: 47px !important;
  height: 47px !important;
  --background: #37a9d8 !important;
  --background-activated: #37a9d8 !important;
}

.icon-btn-send {
  opacity: 1 !important;
  top: 10px;
}

.btn-send {
  margin-top: -12px;
  font-size: 30px;
  width: 45px;
  height: 45px;
  background: transparent;
}

.footer-section {
  background: #e8eaf3;
  display: flex;
  align-items: center;
  min-height: 60px;
}

.footer-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 55px;
}

.input-message {
  padding: 4px 0 10px 8px;
  max-height: 55px;
  overflow: hidden;
}

.confirmation-modal {
  background-color: $modal-backdrop-background;
  padding: 1.5rem;
  --height: auto;

  .modal-wrapper {
    // --height: auto;
    // max-width: 600px;
    max-width: 90vw;
    text-align: center;

    .ion-page {
      position: relative;
      contain: content;
      .modal-content {
        overflow: auto;
      }
    }
  }

  .container {
    padding: 1.2rem;
    text-align: center;

    .controls {
      display: flex;
      justify-content: space-evenly;

      ion-button {
        width: 40%;
      }
    }

    @media (max-width: 600px) {
      .controls {
        flex-direction: column-reverse;

        ion-button {
          width: auto;
        }
      }
    }
  }
}
