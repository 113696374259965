.conversation-item {
  cursor: pointer;
  margin: 5px 0;

  &:hover ion-label {
    color: var(--ion-color-primary);
  }

  ion-label {
    transition: color 100ms ease-in-out;
  }

  ion-note {
    font-size: .8em;
  }
}
.btn-wallet{
  width:100%;
  margin-top:20px;
}
.item .label-wallet{
  display: contents;
  font-size: .8em;
}
