@import '../../theme/variables';

.customer-sidebar {
  ion-header {
    background: var(--ion-color-primary);
    padding: 20px;
    font-size: 1.4em;
    font-weight: bold;
    color: white;
    text-align: center;
  }
}
