@import '../theme/variables.scss';

ion-content .scroll {
  height: 100%;
  padding-top: $notch-padding;
}

ion-slides {
  height:100%;
  .swiper-slide img {
    max-height: 50%;
  }
}

ion-slide.swiper-zoom-container {
  padding: 0 10px;
  display: block;

  h2 {
    font-weight: bold;
    color: $title-color;
  }

  p {
    font-size: 1.1rem;
    text-align: center;

    span {
      color: $title-color;
      font-weight: bolder;
      padding: 5px;
    }
  }

  ol {
    list-style-position: inside;
    text-align: left;
    font-size: 1.1rem;

    li {
      padding-left: 20px;
    }
  }

  ion-button {
    font-size: 1.1rem;
  }

  @media (max-height: 700px) {
    img {
      height: 250px;
    }

    h2 {
      font-size: 1.2rem;
    }

    p, ol {
      font-size: 0.9rem;
    }
  }

  @media (max-height: 600px) {
    img {
      height: 150px;
    }

    h2 {
      font-size: 1.1rem;
    }

    p, ol {
      font-size: 0.8rem;
    }
  }
}

.small {
  font-size: 0.8rem;
}

.muted {
  color: $muted;
}

.login-link {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
  padding-left: 10px;
}

.padding {
  padding: 0.5rem;
}

h1 {
  color: $muted;
}
