.centered {
  display: block;
  text-align: center;
}
.text-sm {
  font-size: .75rem !important;
}
ion-text {
  display: block;
}
